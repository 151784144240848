import './Checkbox.scss';

import classNames from 'classnames';
import { ComponentPropsWithoutRef } from 'react';

type Props = ComponentPropsWithoutRef<'input'> & {
  'data-testid'?: string;
  vertical?: boolean;
};

const className = 'c-Checkbox';

const Checkbox = ({
  id,
  children,
  name,
  'data-testid': dataTestId,
  vertical,
  ...props
}: Props) => {
  return (
    <div
      className={classNames(className, {
        [`${className}--vertical`]: vertical,
      })}
    >
      <input
        className={`${className}__input`}
        id={id || name}
        name={name}
        data-testid={dataTestId}
        type="checkbox"
        {...props}
      />
      <label className={`${className}__label`} htmlFor={id || name}>
        {children}
      </label>
    </div>
  );
};

export default Checkbox;
